import React, {useEffect, useState} from "react";
import { 
    Modal, 
    Button, 
    FormGroup, 
    Checkbox, 
    ControlLabel, 
    Glyphicon,
    Well,
    Collapse
} from "react-bootstrap";
import { View } from "react-native";
import {
    filterOptions
} from "../utils/Common";
import "./SharingModal.css";
import CamViews from "../images/cam-view-numbers.png";

export default function MotionHistoryFilterModal({
    show,
    setShowFilterModal,
    eventParamaters,
    setEventParameters,
    applyFilters,
    props
}) {
    const [buttonSize, setButtonSize] = useState("large");
    const [showHelp, setShowHelp] = useState(false);
    const [helpText, setHelpText] = useState(null);
    const [showCamHelp, setShowCamHelp] = useState(false);

    useEffect(() => {
        if(window.innerWidth<767) {
            setButtonSize("small");
        }
    },[]);

    function handleSelectionChange(idx) {
        let tempEventParams = JSON.parse(JSON.stringify(eventParamaters));
        tempEventParams.selectedFilters[filterOptions[idx]] = !tempEventParams.selectedFilters[filterOptions[idx]];
        setEventParameters(tempEventParams);
    }

    function handleBooleanParamChange(property) {
        let tempEventParams = JSON.parse(JSON.stringify(eventParamaters));
        tempEventParams[property] = !tempEventParams[property];
        setEventParameters(tempEventParams);
    }

    function resetFilters() {
        let tempEventParams = JSON.parse(JSON.stringify(eventParamaters));
        for(var i=0;i<3;i++) {
            tempEventParams.selectedFilters[filterOptions[i]] = true;
        }
        tempEventParams.cars = true;
        tempEventParams.persons = true;
        tempEventParams.realMotion = true;
        setEventParameters(tempEventParams);
    }

    const HelpButton = ({titleText,hText}) => {
        return (
            <View style={{flex:1, flexDirection:"row"}}>
                <ControlLabel>{titleText}</ControlLabel>
                <View style={{paddingLeft:"5px"}}>
                    <Button
                        bsStyle="default"
                        bsSize="small"
                        onClick={() => {
                            setShowHelp(true);
                            setHelpText(hText);
                            if(titleText==="Camera Views to Include") setShowCamHelp(true);
                            else setShowCamHelp(false);
                        }}
                    >
                        <Glyphicon glyph="info-sign" />
                    </Button>
                </View>
            </View>
        )
    }

    const CameraCheckbox = ({idx}) => (
        <Checkbox 
            checked={eventParamaters.selectedFilters[filterOptions[idx]]}
            onChange={() => handleSelectionChange(idx)}
        >
            &nbsp;Camera #{idx+1}
        </Checkbox>
    );

    return (
        <Modal
            show={show}
            onHide={() => setShowFilterModal(false)}
            container={this}
            aria-labelledby="contained-modal-title"
            dialogClassName={buttonSize==="small"?"modal-dialog-phone":"modal-dialog"}
        >
            <Modal.Header closeButton>
                <View style={{flex:1,flexDirection:"row",justifyContent:"center",fontSize:"24px"}}>
                    <div>
                        <b>Motion Filters</b>
                    </div>
                </View>
            </Modal.Header>
            <Modal.Body>
                <View style={{flex:1,flexDirection:buttonSize==="small"?"column":"row",justifyContent:"space-evenly",fontSize:buttonSize==="small"?"14px":"20px"}}>
                    <View style={{flex:1,flexDirection:"column",paddingLeft:"10px"}}>
                        <FormGroup bsSize="large">
                            <HelpButton 
                                titleText="Camera Views to Include"
                                hText=""
                            />
                            <View style={{paddingLeft:"10px"}}>
                                <CameraCheckbox idx={0} />
                                <CameraCheckbox idx={1} />
                                <CameraCheckbox idx={2} />
                                <CameraCheckbox idx={3} />
                            </View>
                        </FormGroup>
                    </View>
                    <View style={{flex:1,flexDirection:"column",paddingLeft:"10px"}}>
                        <FormGroup bsSize="large">
                            <HelpButton
                                titleText="Types of Detections"
                                hText="Our cameras detect lots of things that move on site, cars, trucks, people, etc. 
                                For simplicity, we've grouped these into two categories, People and Vehicles.
                                If you're looking for a specific event, you may find filtering out one of these groups to be helpful in your search."
                            />
                            <View style={{paddingLeft:"10px"}}>
                                <Checkbox
                                    checked={eventParamaters.persons}
                                    onChange={() => handleBooleanParamChange("persons")}
                                >
                                    &nbsp;People
                                </Checkbox>
                                <Checkbox
                                    checked={eventParamaters.cars}
                                    onChange={() => handleBooleanParamChange("cars")}
                                >
                                    &nbsp;Vehicles
                                </Checkbox>
                            </View>
                        </FormGroup>
                        <FormGroup bsSize="large">
                            <HelpButton
                                titleText="Include All Detections"
                                hText="Your camera system detects a lot of events. In general, it errs on the side of caution, and uploads events that may not be real motion. 
                                Our servers take a second look at every event that hits our system and verifies those that it deems more likely to be 'real.' 
                                It marks those and we show you those by default. If you want to see all of the events uploaded to our system, you can check this box."
                            />
                            <View style={{paddingLeft:"10px"}}>
                                <Checkbox
                                    checked={!eventParamaters.realMotion}
                                    onChange={() => handleBooleanParamChange("realMotion")}
                                >
                                    &nbsp;Unverified Motion
                                </Checkbox>
                            </View>
                        </FormGroup>
                    </View>
                </View>
                <View>
                    <Collapse in={showHelp}>
                        <Well>
                            {helpText}
                            <View style={{flex:1,flexDirection:"row",justifyContent:"center"}}>
                                {showCamHelp && (<img src={CamViews} alt="Top Left: Camera #1 | Top Right: Camera #2 | Bottom Left: Camera #3 | Bottom Right: Camera #4" />)}
                            </View>
                        </Well>
                    </Collapse>
                </View>
                <View style={{flex:1, flexDirection:"row", justifyContent:"space-around"}}>
                    <View style={{paddingRight:"5px",width:"50%"}}>
                        <Button
                            bsStyle="info"
                            bsSize="large"
                            block
                            onClick={() => {
                                applyFilters();
                                setShowFilterModal(false);
                            }}
                        >
                            Apply Filters
                        </Button>
                    </View>
                    <View style={{paddingLeft:"5px",width:"50%"}}>
                        <Button
                            bsStyle="danger"
                            bsSize="large"
                            block
                            onClick={resetFilters}
                        >
                            Reset Filters
                        </Button>
                    </View>
                </View>
            </Modal.Body>
        </Modal>
    )
}