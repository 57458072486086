import React, { useState, useEffect, useRef } from "react";
import "./Events.css";
import { ListGroupItem, 
    ListGroup, 
    Glyphicon, 
    Button, 
    PageHeader, 
    OverlayTrigger, 
    Tooltip, 
    DropdownButton, 
    MenuItem, 
    ButtonGroup, 
    Dropdown, 
    Checkbox,
    Panel,
    PanelGroup
} from "react-bootstrap";
// import { Modal } from "react-bootstrap";
import { View } from "react-native";
// import Select from "react-select";
import { 
    reformatDate, 
    reformatTime,
    reformatJustDate,
    getCap, 
    storeCap, 
    getUser, 
    getDefaultEventParameters,
    getUserInformation,
    timeDelta, 
    readableMonitorName,
    filterMonitorsByMonitorId,
    setDefaultEventParameters,
    addDaysToDate,
    filterOptions,
    makeMonitorFilter,
    removeDefaultEventParameters
} from "../utils/Common";
import { 
    loadCap, 
    getMonitors, 
    deleteEventVideo, 
    recordUserAction, 
    getThumbUrl, 
    getCapEvents,
    patchUserNotification
} from '../utils/bedrockAPI';
import DateFnsUtils from '@date-io/date-fns';
import { ThemeProvider } from '@material-ui/styles';
import styled from 'styled-components';
import { DateTimePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { createTheme } from "@material-ui/core/styles";
// import Replay from "./Replay";
import InfiniteScroll from "react-infinite-scroll-component";
import { ThreeDots } from "react-loader-spinner";
import MotionHistoryFilterModal from "../components/MotionHistoryFilterModal";
import MotionEvent from "./MotionEvent";

// import { CalendarPicker, RangePicker } from 'react-minimal-datetime-range';
// import 'react-minimal-datetime-range/lib/react-minimal-datetime-range.min.css';
// import DateTimeRangePicker from '@wojtekmaj/react-datetimerange-picker';
// import DatePicker from "react-datepicker";
// import LoaderButton from '../components/LoaderButton';

// const sorts = ["Sort by: Newest","Sort by: Oldest","Sort by: Name (A-Z)", "Sort by: Name (Z-A)", "Sort by: Longest", "Sort by: Shortest"];
const sorts = ["Sort: Newest","Sort: Oldest","Sort: Name (A-Z)", "Sort: Name (Z-A)"]; // TODO: Fix longest/shortest
// const sortsOrderBysCaptures = ["capture_date DESC","caputer_date ASC","name ASC","name DESC", "length DESC", "length ASC"];
// const sortsOrderBysEvents = ["startDate DESC","startDate ASC","name ASC","name DESC","time DESC", "time ASC"];
const sortsOrderBysEvents = ["startDate DESC","startDate ASC","name ASC","name DESC"]; // TODO: Fix longest/shortest

const DesktopDateTimePicker = styled(DateTimePicker)`
    & .MuiInputBase-input {
        font-size: 24px;
        border: 1px solid black;
        padding: 5px;
        cursor: pointer;
        text-align: center;
        max-width: 200px;
    }
    
    & .MuiInputBase-input:hover {
        background-color: #F5F5F5;
    }
`;

const MobileDateTimePicker = styled(DateTimePicker)`
    & .MuiInputBase-input {
        font-size: 16px;
        border: 1px solid black;
        padding: 5px;
        cursor: pointer;
        text-align: center;
        max-width: 100px;
    }
    
    & .MuiInputBase-input:hover {
        background-color: #F5F5F5;
    }
`;

export default function Events2(props) {
    const [cap, setCap] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [events, setEvents] = useState([]);
    const [nextPageEvents, setNextPageEvents] = useState(null);
    const [monitors, setMonitors] = useState(null);
    const [renderError, setRenderError] = useState(false);
    const [buttonSize, setButtonSize] = useState("large");
    // const [expandables, setExpandables] = useState([]);

    var tempSelectedFilters = {}
    for(var i=0; i<filterOptions.length; i++) {
        tempSelectedFilters[filterOptions[i]] = true;
    }
    const tempEventParams = {
        selectedFilters:tempSelectedFilters,
        realMotion:true,
        pageNumber:0,
        sortParameterNumber:0,
        daylight:true,
        startDate:addDaysToDate(new Date(),-7),
        endDate:new Date(),
        cars:true,
        persons:true
    }
    const [eventParameters, setEventParameters] = useState(tempEventParams);
    // const [daylightFilter, setDaylightFilter] = useState({daylight:true,night:true});
    const [time, setTime] = useState(0);
    const [thumbs, setThumbs] = useState({});
    const [showFilterModal, setShowFilterModal] = useState(false);
    const componentMounted = useRef(true);

    useEffect(() => {
        if(window.innerWidth<767) {
            setButtonSize("small");
        }
        const interval = setInterval(() => {
            setTime(time => time+1);
        },500);
        return () => {
            clearInterval(interval)
            componentMounted.current = false; // Master Component unmounted command for async functions
        };
    },[]);

    useEffect(() => {
        if(props.match.params.notificationid) {
            const update = {clickDate:new Date()};
            patchUserNotification(props.match.params.notificationid,update);
        }
    },[props.match.params.notificationid])

    useEffect(() => {
        let isMounted = true;
        async function accessCap() {
            try {
                setIsLoading(true);
                var tempCap = getCap();
                if(tempCap == null || tempCap.capId !== props.match.params.capid  || !tempCap.architecture) {
                    tempCap = await loadCap(props.match.params.capid);
                    if(isMounted)
                        storeCap(tempCap);
                }
                if(isMounted) {
                    setCap(tempCap);
                    const tempMonitors = await getMonitors(props.match.params.capid);
                    // setMonitors(filterMonitorsByMode(tempMonitors,"Modect"));
                    if(isMounted) {
                        setMonitors(tempMonitors);
                        setIsLoading(false);
                    }
                }
            } catch(e) {
                if(isMounted)
                    setRenderError(true);
            }
        }
        var storedParameters = getDefaultEventParameters();
        if(storedParameters && storedParameters[props.match.params.capid]) {
            setEventParameters(storedParameters[props.match.params.capid]);
            // console.log("from storage",storedParameters);
        }
        accessCap();
        recordUserAction(getUser().userId,"events",props.match.params.capid,{'userData':getUserInformation()});
        return () => { isMounted = false; };
    },[props.match.params.capid]);

    useEffect(() => {
        if(cap && monitors) {
            setIsLoading(true);
            changePage(0,eventParameters.sortParameterNumber,eventParameters.pageNumber,eventParameters.selectedFilters,eventParameters.realMotion,eventParameters.startDate,eventParameters.endDate,eventParameters.cars,eventParameters.persons);
            setIsLoading(false);
        }
    },[cap,monitors])

    useEffect(() => {
        let isMounted = true;

        async function findThumbs() {
            // var tempThumbs = {}
            for(var i=0; i<events.length; i++) {
                if(events[i].imageId) {
                    if(thumbs[events[i].eventId]) {
                        continue;
                    }
                    const tempThumb = await getThumbUrl(events[i].imageId);
                    if(isMounted) {
                        setThumbs(thumbs => ({...thumbs,[events[i].eventId]:tempThumb}));
                    }
                    // if(!tempThumb[events[i].eventId]) {
                    //     tempThumbs[events[i].eventId] = tempThumb;
                    //     if(isMounted) {
                    //         setThumbs(tempThumbs);
                    //     }
                    // }
                }
            }
        }
        if(events && events.length>0) {
            findThumbs();
            // let tempExpandables = [];
            // for(var i=0; i<events.length; i++) {
            //     tempExpandables.push(false);
            // }
            // setExpandables(tempExpandables);
        }
        return () => { isMounted = false; };
    },[events]);

    // useEffect(() => {
    //     if(expandables) console.log(expandables);
    // },[expandables]);

    const changeStartTime = function(startTime) {
        if(cap!==null && events!=null) {
            changePage(0,eventParameters.sortParameterNumber,eventParameters.pageNumber,eventParameters.selectedFilters,eventParameters.realMotion,startTime,eventParameters.endDate,eventParameters.cars,eventParameters.persons,true)
        }
    }

    const changeEndTime = function(endTime) {
        if(cap!==null && events!==null) {
            changePage(0,eventParameters.sortParameterNumber,eventParameters.pageNumber,eventParameters.selectedFilters,eventParameters.realMotion,eventParameters.startDate,endTime,eventParameters.cars,eventParameters.persons,true);
        }
    }

    // async function changePage(n,sortParamater,pn,selectedMonitors=null,real=null,sd=null,ed=null,df=null,dmit=null,cars=null,persons=null) {
    async function changePage(n,sortParamater,pn,selectedMonitors=null,real=null,sd=null,ed=null,cars=null,persons=null,reset=false) {
        // console.log(n,sortParamater,pn,selectedMonitors,real);
        if(reset) {
            setIsLoading(true); // -- INFINITE
            setEvents(null); // -- INFINITE
        }
        setTime(0);
        var storedEventParameters = getDefaultEventParameters();
        if(!storedEventParameters) storedEventParameters = {};
        if(!storedEventParameters[cap.capId]) storedEventParameters[cap.capId] = {};
        if(real==null) real = eventParameters.realMotion;
        var monitorIds = makeMonitorFilter(monitors,selectedMonitors);
        var daylight = null;
        var tempEventParams = JSON.parse(JSON.stringify(eventParameters));
        tempEventParams.pageNumber = pn+n;
        tempEventParams.sortParameterNumber = sortParamater;
        tempEventParams.selectedFilters = selectedMonitors;
        tempEventParams.realMotion = real;
        tempEventParams.daylight = daylight;
        tempEventParams.startDate = sd;
        tempEventParams.endDate = ed;
        tempEventParams.cars = cars;
        tempEventParams.persons = persons;
        setEventParameters(tempEventParams);
        storedEventParameters[cap.capId] = tempEventParams;
        setDefaultEventParameters(storedEventParameters);
        var startDateCall = null;
        var endDateCall = null;
        if(sd) {
            startDateCall = sd.toLocaleString("en-US",{timezone:"UTC"});
        }
        if(ed) {
            endDateCall = ed.toLocaleString("en-US",{timezone:"UTC"});
        }
        // if(!df) df = daylightFilter;
        // if(df.daylight && !df.night) daylight = true;
        // if(df.night && !df.daylight) daylight = false;
        // if(!df.night && !df.daylight) daylight = 'none';
        // setDefaultEventParameters(storedEventParameters);
        // console.log("setting",storedEventParameters[cap.capId].endDate);
        const tempEvents = await getCapEvents(cap.capId,pn+n,sortsOrderBysEvents[sortParamater],daylight,real,monitorIds,startDateCall,endDateCall,cars,persons);
        if(componentMounted.current) {
            if(reset) {
                setEvents(tempEvents); // -- INFINITE
            } else {
                setEvents(events => [...events,...tempEvents]);
            }
            const tempNextPageEvents = await getCapEvents(cap.capId,pn+n+1,sortsOrderBysEvents[sortParamater],daylight,real,monitorIds,startDateCall,endDateCall,cars,persons);
            if(componentMounted.current) {
                setNextPageEvents(tempNextPageEvents);
            }
        
            // storedDaylightParameter[cap.capId] = daylightFilter;
            if(reset) {
                setIsLoading(false); // -- INFINITE
            }
        }
    }

    var prevPage = function () {
        changePage(-1,eventParameters.sortParameterNumber,eventParameters.pageNumber,eventParameters.selectedFilters,eventParameters.realMotion,eventParameters.startDate,eventParameters.endDate,eventParameters.cars,eventParameters.persons);
    }

    var nextPage = function () {
        changePage(+1,eventParameters.sortParameterNumber,eventParameters.pageNumber,eventParameters.selectedFilters,eventParameters.realMotion,eventParameters.startDate,eventParameters.endDate,eventParameters.cars,eventParameters.persons);
    }

    var refreshPage = function () {
        changePage(0,eventParameters.sortParameterNumber,0,eventParameters.selectedFilters,eventParameters.realMotion,eventParameters.startDate,eventParameters.endDate,eventParameters.cars,eventParameters.persons,true);
    }

    function renderPageButtons(buttonType="default") {
        if (events) {
            return (
                <ButtonGroup>
                    <Button
                        bsStyle={buttonType}
                        bsSize={buttonSize}
                        onClick={prevPage}
                        disabled={eventParameters.pageNumber===0}
                    >
                        <Glyphicon glyph="arrow-left" />
                    </Button>
                    <Button
                        disabled={true}
                        bsStyle={buttonType}
                        bsSize={buttonSize}
                    >
                        {eventParameters.pageNumber+1}
                    </Button>
                    <Button
                        bsStyle={buttonType}
                        bsSize={buttonSize}
                        onClick={nextPage}
                        disabled={!nextPageEvents || nextPageEvents.length===0}
                    >
                        <Glyphicon glyph="arrow-right" />
                    </Button>
                </ButtonGroup>
            );
        }
    }

    function renderEventsList() {
        async function removeEvent(event) {
            setIsLoading(true);
            if(window.confirm(`Are you sure you wish to delete ${event.name}?`)) {
                await deleteEventVideo(event.eventId);
                let tempEvents = [];
                for(i=0; i<events.length; i++) {
                    if(events[i].eventId!==event.eventId) {
                        tempEvents.push(events[i]);
                    }
                }
                setEvents(tempEvents);
            }
            setIsLoading(false);
        }
        const snapshotTooltip = (
            <Tooltip id="snapshot" style={{fontSize:"18px"}} disabled={buttonSize==="small"}>
                Ultra-High Resolution Snapshot
            </Tooltip>
        );
        const deleteTooltip = (
            <Tooltip id="delete" style={{fontSize:"18px"}} disabled={buttonSize==="small"}>
                Delete Event
            </Tooltip>
        );
        const videoTooltip = (
            <Tooltip id="delete" style={{fontSize:"18px"}} disabled={buttonSize==="small"}>
                Motion Video
            </Tooltip>
        );
        function capitalizeFirstLetter(string) {
            return string.charAt(0).toUpperCase() + string.slice(1);
        }
        // function setExpanded(expandableIndex) {
        //     let tempExpandables = [...expandables];
        //     tempExpandables[expandableIndex] = !expandables[expandableIndex];
        //     setExpandables(tempExpandables);
        // }
        return (events && events.length > 0 ? (events.map((event,i) => (
                    <View key={event.eventId} style={{flex:1, flexDirection:"row", justifyContent:"flex-start", maxHeight:"100%"}}>
                        {(event.url && event.status && event.status==='IN_S3') && (
                            <View style={{width:"100%"}}>     
                                {/* <ListGroupItem href={`/caps/${cap.capId}/events/${event.eventId}`}> */}
                                <Panel eventKey={event.name}>
                                    {/* <Panel.Heading style={{backgroundColor:"white","&hover":{backgroundColor:"#EEE"}}}> */}
                                    <Panel.Heading>
                                        <Panel.Title toggle>
                                            <View style={{flexDirection:"row"}}>
                                                <View style={{flexGrow:0, width:213, height:120, maxWidth:"50%", paddingRight:"10px"}}>
                                                    {thumbs && thumbs[event.eventId] && (
                                                        <img src={thumbs[event.eventId].url} alt="" width="213" hegiht="120" />
                                                    )}
                                                </View>
                                                <View style={{flexGrow:1}}>
                                                    <span style={{lineHeight:buttonSize==="small"?1.5:2}}>
                                                        <b style={{fontSize:"18px",paddingRight:"10px"}}>{reformatTime(event.startDate)}</b>
                                                        {buttonSize==="small"&&<br />}
                                                        {reformatJustDate(event.startDate)}<br />
                                                        {/* Occured: {reformatTime(event.startDate)}<br /> */}
                                                        {/* Length: {event.frames} frames<br /> */}
                                                        {/* {console.log(event.endDate,typeof(event.endDate))} */}
                                                        {/* Length: {timeDelta(event.startDate,event.endDate)} seconds<br /> */}
                                                        {readableMonitorName(filterMonitorsByMonitorId(monitors,event.monitorId))}<br />
                                                        {event.analysis ? event.analysis.yolo ? capitalizeFirstLetter(event.analysis.yolo[0].class)+ " Detected" : "" : ""}<br />
                                                        {event.analysis && event.analysis.real===false && (<div style={{fontSize:"12px",color:"red"}}>Unverified Motion</div>)}
                                                        {/* Score: {event.score} <br /> */}
                                                    </span>
                                                </View>
                                            </View>
                                        </Panel.Title>
                                    </Panel.Heading>
                                    <Panel.Body collapsible style={{width:"100%",textWrap:"wrap"}}>
                                        {/* <Replay props={props} eventId={event.eventId} /> */}
                                        <MotionEvent
                                            eventId={event.eventId}
                                            reloadEvents={refreshPage}
                                            props={props}
                                        />
                                    </Panel.Body>
                                </Panel>
                                {/* </ListGroupItem> */}
                            </View>
                        )}
                        {/* {event.url && event.imageId && event.status && event.status ==='IN_S3' && ( // Events (old style)
                            <View style={{flexDirection:"column",alignItems:"stretch"}}>
                                <View style={{flexDirection:"row",height:"50%"}}>
                                    <OverlayTrigger placement="bottom" overlay={videoTooltip}>
                                        <Button
                                            bsSize={buttonSize}
                                            bsStyle="warning"
                                            onClick={() => props.history.push(`/caps/${cap.capId}/events/${event.eventId}`)}
                                            style={{height:"100%"}}
                                        >
                                            <Glyphicon style={buttonSize==="large" ? {fontSize:"30px"} : {fontSize:"20px"}} glyph="facetime-video" />
                                        </Button>
                                    </OverlayTrigger>
                                </View>
                                <View style={{flexDirection:"row",height:"50%"}}>
                                    <OverlayTrigger placement="bottom" overlay={snapshotTooltip}>
                                        <Button
                                            bsSize={buttonSize}
                                            bsStyle="warning"
                                            onClick={() => props.history.push(`/caps/${cap.capId}/events/${event.eventId}/snapshot`)}
                                            style={{height:"100%"}}
                                        >
                                            <Glyphicon style={buttonSize==="large" ? {fontSize:"30px"} : {fontSize:"20px"}} glyph="camera" />
                                        </Button>
                                    </OverlayTrigger>
                                </View>
                            </View>
                        )} */}
                        {/* {((event.url&&event.status&&event.status==='IN_S3')||(event.events && event.events[0].url && event.show)) && getUser().caps[props.match.params.capid].accessType==="OWNER" && (
                            <View style={{flexGrow:0}}>
                                <OverlayTrigger placement="bottom" overlay={deleteTooltip}>
                                    <Button
                                        bsSize={buttonSize}  
                                        bsStyle="danger"
                                        onClick={() => removeEvent(event)}
                                        style={{height:"100%"}}
                                    >
                                        <Glyphicon style={buttonSize==="large" ? {fontSize:"30px"} : {fontSize:"20px"}} glyph="remove" />
                                    </Button>
                                </OverlayTrigger>
                            </View>
                        )} */}
                    </View>
                )
            )
        ) : events && events.length===0 ? (
            <ListGroup key={"No Events."} style={{fontSize:"18px"}}>
                No Events Found, consider changing your filter parameters above
            </ListGroup>
        ) : (
            <ListGroupItem key={"No Events Yet"} style={{fontSize: "18px"}}>
                {time > 15 ? "Please Wait. Our servers are a little busy. Events will load momentarily." : time%5===4 ? "Loading...." : time %5===3 ? "Loading..." : time%5===2 ? "Loading.." : "Loading."}
            </ListGroupItem>
        ));
    } 

    async function onSort(eventKey) {
        var tempEP = JSON.parse(JSON.stringify(eventParameters));
        tempEP.pageNumber = 0;
        tempEP.sortParameterNumber = Number(eventKey);
        setEventParameters(tempEP);
        changePage(0,Number(eventKey),0,eventParameters.selectedFilters,eventParameters.realMotion,eventParameters.startDate,eventParameters.endDate,eventParameters.cars,eventParameters.persons);
    }

    async function onFilter(eventKey) {
        var tempEP = JSON.parse(JSON.stringify(eventParameters));
        tempEP.selectedFilters[filterOptions[Number(eventKey)]] = !tempEP.selectedFilters[filterOptions[Number(eventKey)]];
        setEventParameters(tempEP);
        changePage(0,eventParameters.sortParameterNumber,0,tempEP.selectedFilters,eventParameters.realMotion,eventParameters.startDate,eventParameters.endDate,eventParameters.cars,eventParameters.persons);
    }

    // TODO: reenable this 
    // async function onFilterDayLight(eventKey) {
    //     var tempSelectedFilters = JSON.parse(JSON.stringify(daylightFilter));
    //     tempSelectedFilters[eventKey] = !tempSelectedFilters[eventKey];
    //     setDaylightFilter(tempSelectedFilters);
    //     changePage(0,sortParameterNumber,pageNumber,tempSelectedFilters);
    // }
    
    function renderCamEvents() {
        const tooltip = (
            <Tooltip id="refresh" style={{fontSize: "18px"}}>
                Refresh to load new events
            </Tooltip>
        );
        const realMotionTooltip = (
            <Tooltip id="realMotion" style={{fontSize:"18px"}} disabled={buttonSize==="small"}>
                {eventParameters.realMotion ? "Click to show all unfiltered motion" : "Click to show only verified motion"}
            </Tooltip>
        )
        const carsTooltip = (
            <Tooltip id="carsTT" style={{fontSize:"18px"}} disabled={buttonSize==="small"}>
                {eventParameters.cars ? "Click to remove vehicle events" : "Click to show vehicle events"}
            </Tooltip>
        )
        const personsTooltip = (
            <Tooltip id="personsTT" style={{fontSize:"18px"}} disabled={buttonSize==="small"}>
                {eventParameters.persons ? "Click to remove person events" : "Click to show person events"}
            </Tooltip>
        )
        const buttonType = "default";
        const biggerFontMUI = createTheme({
            overrides: {
                MuiInputLabel: {
                    root: {
                        fontSize: "50px",
                    }
                }
            }
        });
        return (
            <View style={{flex:1, flexDirection: "column", zIndex:1000}}>
                <View style={{position:"sticky",top:0,zIndex:1000,background:"#FFF",paddingBottom:"20px"}}>
                    <PageHeader style={{marginTop:"-10px"}}>
                        <View style={{flex: 1, flexDirection: 'row', justifyContent: 'center', zIndex:1000}}>
                            {cap && (buttonSize==="small" ? (
                                <div style={{fontSize:"30px"}}>
                                    Motion History<br />
                                    {cap.name}<small>{cap.neighborhood && " ("+cap.neighborhood+")"}</small>
                                </div>
                            ) : (
                                <div>
                                    Motion History — {cap.name}<small> {cap.neighborhood && " ("+cap.neighborhood+")"}</small>
                                </div>
                            ))}
                        </View>
                    </PageHeader>
                    <View style={{flex:1, flexDirection:"row",justifyContent:"space-between",zIndex:1000}}>
                        {/* <View style={{flex: 1, flexDirection: 'row'}}> */}
                        <View style={{paddingRight:"10px",paddingTop:buttonSize==="small"?"4px":"0px"}}>
                            <ButtonGroup>
                                <Button
                                    bsStyle="primary"
                                    bsSize={buttonSize}
                                    disabled={isLoading}
                                    onClick={() => {
                                        removeDefaultEventParameters();
                                        props.history.push(`/caps/${cap.capId}`);
                                    }}
                                >
                                    Back
                                </Button>
                                {/* <Button
                                    bsStyle="primary"
                                    bsSize={buttonSize}
                                    disabled={isLoading}
                                    onClick={() => props.history.push(`/caps/${cap.capId}/frames`)}
                                >
                                    Frame History
                                </Button> */}
                            </ButtonGroup>
                        </View>
                        <View style={{flex:1, flexDirection:"row", justifyContent:"center", zIndex:1000,flexGrow:1}}>
                            {/* <ThemeProvider theme={biggerFontMUI} > */}
                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                    <View style={{paddingLeft:"5px",paddingTop:"3px"}}>
                                        {/* <div>
                                            Start Range
                                        </div> */}
                                        {buttonSize==="small"?(
                                            <MobileDateTimePicker 
                                                format={"MMM d"} 
                                                value={eventParameters.startDate} 
                                                onChange={changeStartTime} 
                                                maxDate={new Date()} 
                                                InputProps={{disableUnderline:true}}
                                            />
                                        ):(
                                            <DesktopDateTimePicker 
                                                format={"MMM d"} 
                                                value={eventParameters.startDate} 
                                                onChange={changeStartTime} 
                                                maxDate={new Date()} 
                                                InputProps={{disableUnderline:true}}
                                            />
                                        )}
                                    </View>
                                    <View style={buttonSize==="small"?{fontSize:"20px",paddingLeft:"5px",paddingTop:"7px"}:{fontSize:"35px",paddingLeft:"15px",paddingTop:"5px"}}>
                                        <Glyphicon glyph="resize-horizontal" />
                                    </View>
                                    <View style={{paddingLeft:buttonSize==="small"?"5px":"15px",paddingTop:"3px"}}>
                                        {/* <div>
                                            End Range
                                        </div> */}
                                        {buttonSize==="small"?(
                                            <MobileDateTimePicker 
                                                format={"MMM d"}
                                                value={eventParameters.endDate} 
                                                onChange={changeEndTime} 
                                                minDate={eventParameters.startDate}
                                                InputProps={{disableUnderline:true}}
                                            />
                                        ):(
                                            <DesktopDateTimePicker 
                                                format={"MMM d"}
                                                value={eventParameters.endDate} 
                                                onChange={changeEndTime} 
                                                minDate={eventParameters.startDate}
                                                InputProps={{disableUnderline:true}}
                                            />
                                        )}
                                    </View>
                                </MuiPickersUtilsProvider>
                            {/* </ThemeProvider> */}
                        </View>
                        <View style={{paddingLeft:"15px",paddingTop:buttonSize==="small"?"4px":"0px"}}>
                            <Button
                                bsStyle="success"
                                bsSize={buttonSize}
                                onClick={e => setShowFilterModal(!showFilterModal)}
                                disabled={isLoading}
                            >Filter</Button>
                        </View>
                        {/* <View style={{flex:1, flexDirection:"row",justifyContent:"flex-end", zIndex:1000,paddingBottom:"0px",flexWrap:"wrap",flexGrow:2}}>
                            <View style={{paddingRight: "5px", paddingBottom:"5px"}}>
                                <OverlayTrigger placement="left" overlay={tooltip}>
                                    <Button
                                        bsStyle={buttonType}
                                        bsSize={buttonSize}
                                        onClick={() => {changePage(0,eventParameters.sortParameterNumber,0,eventParameters.selectedFilters,eventParameters.realMotion,eventParameters.startDate,eventParameters.endDate,eventParameters.cars,eventParameters.persons)}}
                                    >
                                        <Glyphicon glyph="refresh"></Glyphicon>
                                    </Button>
                                </OverlayTrigger>
                            </View>
                            <View style={{paddingRight:"5px"}}>
                                <DropdownButton
                                    bsStyle={buttonType}
                                    bsSize={buttonSize}
                                    title={sorts[eventParameters.sortParameterNumber]}
                                    key="1"
                                    id="sortDropdownSelector"
                                    sytle={{zIndex:1000}}
                                >
                                    {sorts.map((sort,i) => (
                                        <MenuItem key={sort} eventKey={i.toString()} onSelect={onSort}>{sort}</MenuItem>
                                    ))}
                                </DropdownButton>
                            </View>
                            { events && (
                                <React.Fragment>
                                    <View style={{paddingRight: "5px"}}>
                                        <Dropdown
                                            bsStyle={buttonType}
                                            bsSize={buttonSize}
                                            key="1"
                                            id="filterDropdownSelector"
                                            style={{zIndex:1000}}
                                        >
                                            <Dropdown.Toggle noCaret>
                                                <Glyphicon glyph="filter" />
                                            </Dropdown.Toggle>
                                            <Dropdown.Menu>
                                                {filterOptions.map((f,i)=>(
                                                    <MenuItem key={f} eventKey={i.toString()} onSelect={onFilter}>
                                                        <Checkbox onChange={() => void(0)} checked={eventParameters.selectedFilters[f]||false} value={f || ''}>{f}</Checkbox>
                                                    </MenuItem>
                                                ))}
                                            </Dropdown.Menu>
                                        </Dropdown>
                                    </View>
                                    {cap && cap.architecture && ( cap.architecture.includes("CHLORINE") || cap.architecture.includes("BROMINE") || cap.architecture.includes("ADAFRUIT") || cap.architecture.includes("XAVIER")) && (
                                        <React.Fragment>
                                            <View style={{paddingRight:"5px"}}>
                                                <OverlayTrigger overlay={carsTooltip} placement="top">
                                                    <Button 
                                                        bsStyle={eventParameters.cars ? "success" : buttonType }
                                                        bsSize={buttonSize}
                                                        onClick={() => {changePage(0,eventParameters.sortParameterNumber,0,eventParameters.selectedFilters,eventParameters.realMotion,eventParameters.startDate,eventParameters.endDate,!eventParameters.cars,eventParameters.persons)}}
                                                    >
                                                        <svg xmlns="http://www.w3.org/2000/svg" width={buttonSize==="small"?"12":"20"} height={buttonSize==="small"?"12":"20"} fill="currentColor" className="bi bi-car-front-fill" viewBox="0 0 16 16">
                                                            <path fillRule="evenodd" d="M2.52 3.515A2.5 2.5 0 0 1 4.82 2h6.362c1 0 1.904.596 2.298 1.515l.792 1.848c.075.175.21.319.38.404.5.25.855.715.965 1.262l.335 1.679c.033.161.049.325.049.49v.413c0 .814-.39 1.543-1 1.997V13.5a.5.5 0 0 1-.5.5h-2a.5.5 0 0 1-.5-.5v-1.338c-1.292.048-2.745.088-4 .088s-2.708-.04-4-.088V13.5a.5.5 0 0 1-.5.5h-2a.5.5 0 0 1-.5-.5v-1.892c-.61-.454-1-1.183-1-1.997v-.413a2.5 2.5 0 0 1 .049-.49l.335-1.68c.11-.546.465-1.012.964-1.261a.807.807 0 0 0 .381-.404l.792-1.848ZM3 10a1 1 0 1 0 0-2 1 1 0 0 0 0 2Zm10 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2ZM6 8a1 1 0 0 0 0 2h4a1 1 0 1 0 0-2H6ZM2.906 5.189l.956-1.913A.5.5 0 0 1 4.309 3h7.382a.5.5 0 0 1 .447.276l.956 1.913a.51.51 0 0 1-.497.731c-.91-.073-3.35-.17-4.597-.17-1.247 0-3.688.097-4.597.17a.51.51 0 0 1-.497-.731Z"/>
                                                        </svg>
                                                    </Button>
                                                </OverlayTrigger>
                                            </View>
                                            <View style={{paddingRight:"5px"}}>
                                                <OverlayTrigger overlay={personsTooltip} placement="top">
                                                    <Button 
                                                        bsStyle={eventParameters.persons ? "success" : buttonType }
                                                        bsSize={buttonSize}
                                                        onClick={() => {changePage(0,eventParameters.sortParameterNumber,0,eventParameters.selectedFilters,eventParameters.realMotion,eventParameters.startDate,eventParameters.endDate,eventParameters.cars,!eventParameters.persons)}}
                                                    >
                                                        <svg xmlns="http://www.w3.org/2000/svg" width={buttonSize==="small"?"12":"20"} height={buttonSize==="small"?"12":"20"} fill="currentColor" className="bi bi-people" viewBox="0 0 16 16">
                                                            <path d="M15 14s1 0 1-1-1-4-5-4-5 3-5 4 1 1 1 1h8zm-7.978-1A.261.261 0 0 1 7 12.996c.001-.264.167-1.03.76-1.72C8.312 10.629 9.282 10 11 10c1.717 0 2.687.63 3.24 1.276.593.69.758 1.457.76 1.72l-.008.002a.274.274 0 0 1-.014.002H7.022zM11 7a2 2 0 1 0 0-4 2 2 0 0 0 0 4zm3-2a3 3 0 1 1-6 0 3 3 0 0 1 6 0zM6.936 9.28a5.88 5.88 0 0 0-1.23-.247A7.35 7.35 0 0 0 5 9c-4 0-5 3-5 4 0 .667.333 1 1 1h4.216A2.238 2.238 0 0 1 5 13c0-1.01.377-2.042 1.09-2.904.243-.294.526-.569.846-.816zM4.92 10A5.493 5.493 0 0 0 4 13H1c0-.26.164-1.03.76-1.724.545-.636 1.492-1.256 3.16-1.275zM1.5 5.5a3 3 0 1 1 6 0 3 3 0 0 1-6 0zm3-2a2 2 0 1 0 0 4 2 2 0 0 0 0-4z"/>
                                                        </svg>
                                                    </Button>
                                                </OverlayTrigger>
                                            </View>
                                        </React.Fragment>
                                    )}
                                    <View style={{paddingRight:"5px"}}>
                                        <OverlayTrigger overlay={realMotionTooltip} placement="top">
                                            <Button
                                                bsStyle={eventParameters.realMotion ? buttonType : "danger"}
                                                bsSize={buttonSize}
                                                onClick={() => {changePage(0,eventParameters.sortParameterNumber,0,eventParameters.selectedFilters,!eventParameters.realMotion,eventParameters.startDate,eventParameters.endDate,eventParameters.cars,eventParameters.persons)}}
                                            >
                                                <Glyphicon glyph="asterisk" />
                                            </Button>
                                        </OverlayTrigger>
                                    </View>
                                </React.Fragment>
                            )}
                            <View>
                                {renderPageButtons()}
                            </View>
                        </View> */}
                        <MotionHistoryFilterModal
                            show={showFilterModal}
                            setShowFilterModal={setShowFilterModal}
                            eventParamaters={eventParameters}
                            setEventParameters={setEventParameters}
                            applyFilters={refreshPage}
                            props={props}
                        />

                    </View>
                </View>
                {   
                    cap && cap.architecture && cap.architecture.substring(0,6) === "ODROID" && monitors && events && 
                    (
                        <InfiniteScroll
                            dataLength={events.length}
                            next={nextPage}
                            hasMore={nextPageEvents==null || nextPageEvents.length!==0}
                            loader={<View style={{flex:1,flexDirection:"row",justifyContent:"center"}}><ThreeDots visible={true} color={"#337ab7"} /></View>}
                            endMessage={
                                <p style={{textAlign:"center"}}>
                                    {events && events.length>0 && (<b>Adjust Date Filters to Find More Events</b>)}
                                </p>
                            }
                            refreshFunction={refreshPage}
                            pullDownToRefresh
                            pullDownToRefreshThreshold={50}
                            pullDownToRefreshContent={
                                <h3 style={{ textAlign: 'center' }}>&#8595; Pull down to refresh</h3>
                            }
                            releaseToRefreshContent={
                                <h3 style={{ textAlign: 'center' }}>&#8593; Release to refresh</h3>
                            }
                        >
                            <PanelGroup id="List-of-events" style={{zIndex:100, paddingTop:"20px"}}>
                                {renderEventsList()}
                            </PanelGroup>
                        </InfiniteScroll>
                    )
                }
                {/* <View style={{flex: 1, flexDirection: "row", justifyContent: "flex-end"}}>
                    {!isLoading && events && events.length>0 && renderPageButtons()}
                </View> */}
            </View>
        )
    }

    function renderNone() {
        return (
            <div style={{paddingTop: "100px", textAlign: "center"}}>
                <h3>Your CAP is not online or is not set up yet, please contact Bedrock Wireless for assistance.</h3>
            </div>
        );
    }
    
    return (
        <div className="Events">
            {renderError ? renderNone() : (
                renderCamEvents()
            )}
            {/* {events && allEmails && renderSharingModal()} */}
        </div>
    )
}